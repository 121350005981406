import { chakra } from '@chakra-ui/react';
/**
 * Import Formats:
 * - @material-design-icons/svg/VARIANT/NAME.svg
 *   - link: https://fonts.google.com/icons?icon.set=Material+Icons
 *   - VARIANT: filled | outlined | round | sharp | two-tone
 *   - NAME: name of icon
 * - @material-symbols/svg-WEIGHT/VARIANT/NAME.svg
 *   - link: https://fonts.google.com/icons?icon.set=Material+Symbols
 *   - WEIGHT: 400 (other weights are not currently installed)
 *   - VARIANT: outlined | rounded | sharp
 *   - NAME: name of icon (can be suffixed with -fill to get filled variant)
 */
import add from '@material-symbols/svg-400/outlined/add.svg?react';
import admin from '@material-symbols/svg-400/outlined/shield_person.svg?react';
import apartment from '@material-symbols/svg-400/rounded/apartment.svg?react';
import arrowBack from '@material-symbols/svg-400/outlined/arrow_back.svg?react';
import arrowDownward from '@material-symbols/svg-400/outlined/arrow_downward.svg?react';
import arrowDropDown from '@material-symbols/svg-400/sharp/arrow_drop_down.svg?react';
import arrowForward from '@material-symbols/svg-400/outlined/arrow_forward.svg?react';
import arrowRange from '@material-symbols/svg-400/outlined/arrow_range.svg?react';
import arrowUpward from '@material-symbols/svg-400/outlined/arrow_upward.svg?react';
import arrow_forward from '@material-symbols/svg-400/outlined/arrow_forward.svg?react';
import arrow_right_alt from '@material-symbols/svg-400/outlined/arrow_right_alt.svg?react';
import barChart from '@material-design-icons/svg/filled/bar_chart.svg?react';
import captivePortal from '@material-symbols/svg-400/outlined/captive_portal.svg?react';
import category from '@material-design-icons/svg/filled/category.svg?react';
import check from '@material-symbols/svg-400/outlined/check.svg?react';
import close from '@material-symbols/svg-400/outlined/close.svg?react';
import commit from '@material-symbols/svg-400/outlined/commit.svg?react';
import compareArrows from '@material-symbols/svg-500/rounded/compare_arrows.svg?react';
import corporate_fare from '@material-symbols/svg-500/outlined/corporate_fare.svg?react';
import crowdSource from '@material-symbols/svg-400/outlined/crowdsource.svg?react';
import dangerous from '@material-symbols/svg-400/outlined/dangerous.svg?react';
import doNotTouch from '@material-symbols/svg-400/outlined/do_not_touch.svg?react';
import dragIndicator from '@material-symbols/svg-400/outlined/drag_indicator.svg?react';
import expandCircleDown from '@material-symbols/svg-400/outlined/expand_circle_down.svg?react';
import expandCircleRight from '@material-symbols/svg-400/outlined/expand_circle_right.svg?react';
import favorite from '@material-symbols/svg-500/rounded/favorite.svg?react';
import gavel from '@material-symbols/svg-400/outlined/gavel.svg?react';
import group from '@material-symbols/svg-500/outlined/group.svg?react';
import info from '@material-design-icons/svg/filled/info.svg?react';
import language from '@material-symbols/svg-500/outlined/language.svg?react';
import locationOn from '@material-symbols/svg-500/rounded/location_on.svg?react';
import lock from '@material-symbols/svg-400/outlined/lock.svg?react';
import mail from '@material-symbols/svg-500/outlined/mail.svg?react';
import menu from '@material-symbols/svg-400/outlined/menu.svg?react';
import moveToInbox from '@material-symbols/svg-500/outlined/move_to_inbox.svg?react';
import mystery from '@material-symbols/svg-500/rounded/mystery.svg?react';
import payments from '@material-design-icons/svg/filled/payments.svg?react';
import person from '@material-symbols/svg-400/rounded/person-fill.svg?react';
import personAdd from '@material-symbols/svg-400/outlined/person_add.svg?react';
import personAddFill from '@material-symbols/svg-400/outlined/person_add-fill.svg?react';
import personCelebrate from '@material-symbols/svg-400/outlined/person_celebrate.svg?react';
import place from '@material-design-icons/svg/filled/place.svg?react';
import psychologyAlt from '@material-symbols/svg-400/outlined/psychology_alt.svg?react';
import rightPanelOpen from '@material-symbols/svg-400/outlined/right_panel_open.svg?react';
import rocketLaunch from '@material-symbols/svg-400/outlined/rocket_launch.svg?react';
import schedule from '@material-symbols/svg-400/outlined/schedule.svg?react';
import search from '@material-symbols/svg-400/outlined/search.svg?react';
import settings from '@material-symbols/svg-400/outlined/settings.svg?react';
import signalCellularAlt from '@material-symbols/svg-300/outlined/signal_cellular_alt.svg?react';
import snooze from '@material-symbols/svg-400/outlined/snooze.svg?react';
import stacks from '@material-symbols/svg-500/rounded/stacks.svg?react';
import store from '@material-symbols/svg-400/outlined/store.svg?react';
import style from '@material-symbols/svg-500/rounded/style.svg?react';
import sync from '@material-symbols/svg-400/outlined/sync.svg?react';
import upload from '@material-symbols/svg-500/outlined/upload.svg?react';
import visibility from '@material-symbols/svg-500/outlined/visibility.svg?react';
import inventory from '@material-symbols/svg-400/outlined/inventory.svg?react';
import checkbook from '@material-symbols/svg-400/outlined/checkbook.svg?react';
import download from '@material-symbols/svg-400/outlined/download.svg?react';
import description from '@material-symbols/svg-400/outlined/description.svg?react';
import workspaces from '@material-symbols/svg-400/outlined/workspaces.svg?react';
import accountBalance from '@material-symbols/svg-400/outlined/account_balance.svg?react';
import expand from '@material-symbols/svg-400/outlined/expand.svg?react';
import restart from '@material-symbols/svg-400/outlined/restart_alt.svg?react';
import payments_outlined from '@material-symbols/svg-400/outlined/payments.svg?react';
import price_check from '@material-symbols/svg-400/outlined/price_check.svg?react';
import lock_open from '@material-symbols/svg-400/outlined/lock_open.svg?react';
import healthMetrics from '@material-symbols/svg-400/outlined/health_metrics.svg?react';
import bookmark from '@material-symbols/svg-400/outlined/bookmark.svg?react';
import bookmarkFill from '@material-symbols/svg-400/outlined/bookmark-fill.svg?react';
import shoppingCart from '@material-symbols/svg-400/outlined/shopping_cart.svg?react';
import sdk from '@material-symbols/svg-400/outlined/sdk.svg?react';
import biotech from '@material-symbols/svg-400/outlined/biotech.svg?react';
import eco from '@material-symbols/svg-400/outlined/eco.svg?react';
import sell from '@material-symbols/svg-400/outlined/sell.svg?react';
import liveTv from '@material-symbols/svg-400/outlined/live_tv.svg?react';
import precisionManufacturing from '@material-symbols/svg-400/outlined/precision_manufacturing.svg?react';
import currencyBitcoin from '@material-symbols/svg-400/outlined/currency_bitcoin.svg?react';
import package2 from '@material-symbols/svg-400/outlined/package_2.svg?react';
import localShipping from '@material-symbols/svg-400/outlined/local_shipping.svg?react';
import school from '@material-symbols/svg-400/outlined/school.svg?react';
import sportsEsports from '@material-symbols/svg-400/outlined/sports_esports.svg?react';
import factory from '@material-symbols/svg-400/outlined/factory.svg?react';
import restaurant from '@material-symbols/svg-400/outlined/restaurant.svg?react';
import electricBolt from '@material-symbols/svg-400/outlined/electric_bolt.svg?react';
import realEstateAgent from '@material-symbols/svg-400/outlined/real_estate_agent.svg?react';
import beachAccess from '@material-symbols/svg-400/outlined/beach_access.svg?react';
import shieldPerson from '@material-symbols/svg-400/outlined/shield_person.svg?react';
import agriculture from '@material-symbols/svg-400/outlined/agriculture.svg?react';
import engineering from '@material-symbols/svg-400/outlined/engineering.svg?react';
import help from '@material-symbols/svg-400/outlined/help.svg?react';
import wavingHand from '@material-symbols/svg-400/outlined/waving_hand.svg?react';
import passkey from '@material-symbols/svg-400/outlined/passkey.svg?react';
import menuOpen from '@material-symbols/svg-400/outlined/menu_open.svg?react';
import exclamation from '@material-symbols/svg-400/outlined/exclamation.svg?react';
import docsAddOn from '@material-symbols/svg-400/outlined/docs_add_on.svg?react';
import labs from '@material-symbols/svg-400/outlined/labs.svg?react';
import personOff from '@material-symbols/svg-400/outlined/person_off.svg?react';
import groupAdd from '@material-symbols/svg-400/outlined/group_add.svg?react';
import autorenew from '@material-symbols/svg-400/outlined/autorenew.svg?react';
import home from '@material-symbols/svg-400/outlined/home.svg?react';
import handshake from '@material-symbols/svg-400/outlined/handshake.svg?react';
import addAPhoto from '@material-symbols/svg-400/outlined/add_a_photo.svg?react';
import save from '@material-symbols/svg-400/outlined/save.svg?react';
import remove from '@material-symbols/svg-400/outlined/delete.svg?react';
import openInNew from '@material-symbols/svg-400/outlined/open_in_new.svg?react';
import undo from '@material-symbols/svg-400/outlined/undo.svg?react';
import redo from '@material-symbols/svg-400/outlined/redo.svg?react';
import bold from '@material-symbols/svg-400/outlined/format_bold.svg?react';
import italic from '@material-symbols/svg-400/outlined/format_italic.svg?react';
import underline from '@material-symbols/svg-400/outlined/format_underlined.svg?react';
import strikethrough from '@material-symbols/svg-400/outlined/format_strikethrough.svg?react';
import leftAlign from '@material-symbols/svg-400/outlined/align_horizontal_left.svg?react';
import centerAlign from '@material-symbols/svg-400/outlined/align_horizontal_center.svg?react';
import rightAlign from '@material-symbols/svg-400/outlined/align_horizontal_right.svg?react';
import justify from '@material-symbols/svg-400/outlined/format_align_justify.svg?react';
import edit from '@material-symbols/svg-400/outlined/edit.svg?react';
import alternateEmail from '@material-symbols/svg-400/outlined/alternate_email.svg?react';
import queryStats from '@material-symbols/svg-400/outlined/query_stats.svg?react';
import chevronRight from '@material-symbols/svg-400/outlined/chevron_right.svg?react';
import northEast from '@material-symbols/svg-400/outlined/north_east.svg?react';
import key from '@material-symbols/svg-400/outlined/key.svg?react';
import recordVoiceOver from '@material-symbols/svg-400/outlined/record_voice_over.svg?react';
import publish from '@material-symbols/svg-400/outlined/publish.svg?react';
import swapHoriz from '@material-symbols/svg-400/outlined/swap_horiz.svg?react';
import block from '@material-symbols/svg-400/outlined/block.svg?react';
import qrCodeScanner from '@material-symbols/svg-400/outlined/qr_code_scanner.svg?react';
import openInFull from '@material-symbols/svg-400/outlined/open_in_full.svg?react';
import contentCopy from '@material-symbols/svg-400/outlined/content_copy.svg?react';
import showChart from '@material-symbols/svg-400/outlined/show_chart.svg?react';
import list from '@material-symbols/svg-400/outlined/list.svg?react';
import work from '@material-symbols/svg-400/outlined/work.svg?react';
import moreHoriz from '@material-symbols/svg-400/outlined/more_horiz.svg?react';
import neurology from '@material-symbols/svg-400/outlined/neurology.svg?react';
import fastForward from '@material-symbols/svg-400/outlined/fast_forward.svg?react';
import factCheck from '@material-symbols/svg-400/outlined/fact_check.svg?react';
import campaign from '@material-symbols/svg-400/outlined/campaign.svg?react';
import robot from '@material-symbols/svg-400/outlined/robot_2.svg?react';
import warning from '@material-symbols/svg-400/outlined/warning.svg?react';
import verified from '@material-symbols/svg-400/outlined/verified.svg?react';
import star from '@material-symbols/svg-400/outlined/star.svg?react';
import safetyCheck from '@material-symbols/svg-400/outlined/safety_check.svg?react';
import recommend from '@material-symbols/svg-400/outlined/recommend.svg?react';
import diversity from '@material-symbols/svg-400/outlined/diversity_2.svg?react';
import stickyNote from '@material-symbols/svg-400/outlined/sticky_note_2.svg?react';
import questionMark from '@material-symbols/svg-400/outlined/question_mark.svg?react';
import trip from '@material-symbols/svg-400/outlined/trip.svg?react';

type SVGComponent = React.FunctionComponent<React.SVGProps<SVGSVGElement>>;

const icons = {
  add,
  admin,
  apartment,
  arrowBack,
  arrowDownward,
  arrowDropDown,
  arrowForward,
  arrowRange,
  arrowUpward,
  arrow_forward,
  arrow_right_alt,
  barChart,
  captivePortal,
  category,
  check,
  close,
  commit,
  compareArrows,
  corporate_fare,
  crowdSource,
  dangerous,
  doNotTouch,
  dragIndicator,
  expandCircleDown,
  expandCircleRight,
  favorite,
  gavel,
  group,
  info,
  language,
  locationOn,
  lock,
  mail,
  menu,
  moveToInbox,
  mystery,
  payments,
  person,
  personCelebrate,
  place,
  psychologyAlt,
  rightPanelOpen,
  rocketLaunch,
  schedule,
  search,
  settings,
  signalCellularAlt,
  snooze,
  stacks,
  store,
  style,
  sync,
  upload,
  visibility,
  inventory,
  checkbook,
  download,
  description,
  workspaces,
  accountBalance,
  expand,
  restart,
  payments_outlined,
  price_check,
  lock_open,
  healthMetrics,
  bookmark,
  bookmarkFill,
  shoppingCart,
  biotech,
  sdk,
  eco,
  sell,
  liveTv,
  precisionManufacturing,
  currencyBitcoin,
  package2,
  localShipping,
  school,
  sportsEsports,
  factory,
  restaurant,
  electricBolt,
  realEstateAgent,
  beachAccess,
  shieldPerson,
  agriculture,
  engineering,
  help,
  wavingHand,
  personAdd,
  personAddFill,
  passkey,
  menuOpen,
  exclamation,
  docsAddOn,
  labs,
  personOff,
  groupAdd,
  autorenew,
  home,
  handshake,
  addAPhoto,
  save,
  remove,
  openInNew,
  undo,
  redo,
  bold,
  italic,
  underline,
  strikethrough,
  leftAlign,
  centerAlign,
  rightAlign,
  justify,
  edit,
  alternateEmail,
  queryStats,
  chevronRight,
  northEast,
  key,
  recordVoiceOver,
  publish,
  swapHoriz,
  block,
  qrCodeScanner,
  openInFull,
  contentCopy,
  showChart,
  list,
  work,
  moreHoriz,
  neurology,
  fastForward,
  factCheck,
  campaign,
  robot,
  warning,
  verified,
  star,
  safetyCheck,
  recommend,
  diversity,
  stickyNote,
  questionMark,
  trip,
} as const satisfies Record<string, SVGComponent>;

type IconName = keyof typeof icons;
// const iconNames = Object.keys(icons);
const iconNames = Object.keys(icons) as IconName[];
// const iconNames = Object.keys(icons) as ReadonlyArray<string>;

type IconProps = {
  name: IconName;
  size?: string;
  color?: string;
} & React.ComponentProps<typeof chakra.svg>;

const getSVGComponent = (name: keyof typeof icons): SVGComponent => {
  return icons[name];
};

function Icon({ name, size = '16px', color = 'currentColor', ...props }: IconProps) {
  const Svg = getSVGComponent(name);

  if (!Svg) {
    throw new Error(`Icon not found for name: ${name}`);
  }

  const ChakraSvg = chakra(Svg);

  return <ChakraSvg style={{ flexShrink: 0 }} height={size} width={size} path={color} fill={color} {...props} />;
}

export { Icon, iconNames };
export type { IconName };
